<template>
  <div class="big container">
    <div class="box">
      <div>
        <span class="user_main">昵称：</span>
        <el-input
          v-model="nick_name"
          class="myinput"
          placeholder="限制4-20个字符，可由中英文、数字、_、-组成"
        ></el-input>
      </div>
      <div>
        <span class="user_main">性别：</span>
        <el-select
          v-model="sex"
          placeholder="请选择"
          class="myinput"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <span class="user_main">生日：</span>
        <el-date-picker
          v-model="birthday"
          type="date"
          placeholder="选择日期"
          class="myinput"
        ></el-date-picker>
      </div>

      <div>
        <span class="user_main">密码：</span>
        <el-button
          type="text"
          style="margin-left:20px"
          @click="updatePwd=true;pwd='';pwd_code=''"
        >重设密码</el-button>
      </div>
      <div>
         <span class="user_main">
           绑定微信：
         </span>
         <span style="width: 50px;margin-left:20px;margin-right:10px;">{{userInfo.is_bind_wx===1?'是':'否'}}</span>
         <el-button
          type="text" v-if="userInfo.is_bind_wx==0" @click="bindWxs">绑定</el-button>
         <el-button
          type="text" v-if="userInfo.is_bind_wx==1" @click="deteleBindInfo">解绑</el-button>
      </div>
      <div>
        <img v-if="qr_code" :src="qr_code" alt="" width="200px" style="margin-left:100px;">
      </div>
      <div class="btnbox">
        <el-button
          width="40px"
          @click="goback"
        >
          取消
        </el-button>
        <el-button
          width="40px"
          type="primary"
          @click="saveMsg"
        >保存</el-button>
      </div>
    </div>
    <!-- 设置密码弹窗 -->
    <el-dialog
      title="设置密码"
      :visible.sync="updatePwd"
      width="520px"
    >
      <div class="diabox">
        <div style="margin-bottom:18px">
          <span class="pwd1">手机号码</span>
          <span>{{phone}}</span>
        </div>
        <div>
          <span class="pwd1">验证码</span>
          <el-input
            v-model="sms_code"
            placeholder="输入验证码"
            maxlength="6"
            style="width:200px;margin-right:10px"
          ></el-input>
          <el-button
            type="text"
            @click="sendCode"
            :disabled="repost"
          >{{sendText}}</el-button>
        </div>
        <div>
          <span class="pwd1">密码</span>
          <el-input
            v-model="new_pwd"
            placeholder="输入6-10位密码"
            style="width:200px"
            maxlength="10"
          ></el-input>
        </div>
        <div style="text-align:center">
          <el-button
            type="primary"
            style="width:90px"
            @click="UpdatePwd"
          >确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nick_name: "",
      sex: "",
      birthday: "",
      phone: "",
      options: [
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ],
      times: 120,
      updatePwd: false,
      new_pwd: "",
      sms_code: "",
      sendText: "发送验证码",
      repost: false,
      userInfo:'',
      bindWx:false,
      qr_code:'',
    };
  },

  methods: {
    getInfo() {
      return new Promise((resolve, reject) => {
        this.axios.post("/store/Shopadmin/getInfo").then((res) => {
          let info = res.data.info;
          this.nick_name = info.nick_name;
          this.sex = info.sex ? info.sex : "";
          this.birthday = info.birthday
            ? this.dayjs.unix(info.birthday).format("YYYY-MM-DD")
            : "";
          this.phone = info.phone;
          window.utils.storage.setter("userInfo", info);
          this.userInfo=window.utils.storage.getter("userInfo")
          resolve();
        });
      });
    },
    //保存信息
    saveMsg() {
      let reg = /[\u4e00-\u9fa5a-zA-Z0-9\_]{4,20}/;
      if (!this.nick_name) {
        this.$message.error("请填写昵称");
        return false;
      }
      if (!reg.test(this.nick_name)) {
        this.$message.error("请填写4-20个字符，可由中英文、数字、_、-组成");
        return false;
      }
      let data = {
        nick_name: this.nick_name,
        sex: this.sex,
        birthday: this.birthday
          ? this.dayjs(this.birthday).format("YYYY-MM-DD")
          : "",
      };
      this.axios.post("/store/Shopadmin/updateAdminInfo", data).then((res) => {
        if (res.code == 0) {
          this.$message.success("保存成功");
          this.getInfo().then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        }
      });
    },
    bindWxs(){
      this.bindWx=true
      this.axios.post('/store/shop/getBindQrcode').then(res=>{
        this.qr_code=res.data.qr_code
      })
      let timer = setInterval(() => {
        this.axios.post('/store/shop/getUserInfo').then((res) => {
         if(res.data.is_bind_wx === 1){
           this.bindWx=false
           this.userInfo={
             ...this.userInfo,
             is_bind_wx: 1
           }
           this.$message.success('绑定成功！');
           this.qr_code=''
           window.utils.storage.setter('userInfo', this.userInfo);
           clearInterval(timer)
         }
        })
      }, 2000)
    },
    deteleBindInfo(){
      this.axios.post('/store/Shopadmin/deteleBindInfo').then(res=>{
        this.$message.success('解绑成功！');
                this.axios.post('/store/shop/getUserInfo').then((res) => {
                  if(res.data.is_bind_wx === 0) {
                    this.userInfo={
                      ...this.userInfo,
                      is_bind_wx: 0
                    }
                    window.utils.storage.setter('userInfo', this.userInfo);
                  }
                })
      })
    },
    goback(){
      this.$router.go(-1)
    },
    //修改密码发送验证码
    sendCode() {
      let data = {
        msg_type: 4,
        phone: this.phone,
      };
      console.log(data)
      this.axios.post("/store/Platform/sendMsg", data).then((res) => {
        if (res.code == 0) {
          this.$message.success("验证码已发送");
          this.startTimer(120);
        }
      });
    },
    //验证码倒计时
    startTimer(times) {
      if (times > 0) {
        times--;
        this.repost = true;
        this.sendText = "重新发送" + times + "s";
        setTimeout(() => {
          this.startTimer(times);
        }, 1000);
      } else {
        this.repost = false;
        this.sendText = "发送验证码";
      }
    },
    //修改密码
    UpdatePwd() {
      if (!this.sms_code) {
        this.$message.error("请先获取验证码");
        return false;
      }
      if (!this.new_pwd) {
        this.$message.error("请输入6-10位密码");
        return false;
      // } else {
      //   let reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9])/;
      //   if (this.new_pwd.length < 6) {
      //     this.$message.error("请输入6-20位密码");
      //     return false;
      //   }
      //   if (!reg.test(this.new_pwd)) {
      //     this.$message.error(
      //       "密码必须包含大小写字母、数字、特殊字符任意至少两种的组合"
      //     );
      //     return false;
      //   }
      }
      let data = {
        phone: this.phone,
        new_pwd: this.new_pwd,
        sms_code: this.sms_code,
      };
      this.axios.post("/store/Shopadmin/updateAdminPwd", data).then((res) => {
        if (res.code == 0) {
          this.$message.success("密码已修改,下次登录请使用新密码");
          this.updatePwd = false;
        }
      });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>
<style lang="scss" scoped>
.big {
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
}
.box {
  margin: 100px 0 0 200px;
  div {
    margin-bottom: 16px;
  }
  .btnbox {
    padding-left: 100px;
  }
}
.myinput {
  width: 340px;
  margin-left: 20px;
}
.diabox {
  padding: 20px 40px 0;
  box-sizing: border-box;
  .pwd1 {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 10px;
  }
  div {
    margin-bottom: 10px;
  }
}
.user_main{
  width:80px;
  display:inline-block;
  text-align: right;
}
</style>